<template>
  <div>
    <!--    企业介绍-->
    <div class="content1">
      <div class="top">
        <div class="title">企业介绍</div>
        <div class="line"></div>
        <div class="title1">ABOUT US</div>
      </div>
      <div class="bom">
        <div class="left animated" :class="{ bounceInLeft: show1 }">
          <div class="title">
            <div>公司简介</div>
            <div class="line"></div>
            <div class="words">
              <br/>
              &emsp;&emsp;浙江骐骥软件科技有限公司是一家综合的IT智慧管理咨询和解决方案提供厂商。基于自有软件产品和自研开发平台为各行各业的客户提供全面的软件综合解决方案。
              公司于2017年2月21日创立，有一支专业、朝气蓬勃的软件技术开发及服务团队，技术人员占比高达88%以上。成立至今6年时间公司通过一些的项目实施，形成了自己的特色，其中公司自研发的拳头产品智慧养老板块，取得了多项软著及发明专利，分布浙江、山东、陕西、北京等地。
              公司的发展离不开客户的肯定与支持，我司一直重视客户的关系维护和产品质量，长期稳定的主要客户有外资企业如日立集团（日立解决方案（中国）有限公司，浙江日立解决方案软件服务有限公司）、机关事业单位（嘉兴市本级，县市级各级政府部分的局委办，机关单位），本地国央企业（嘉科电子，嘉兴数字实验室等）、国内通信运营商（移动，联通，电信，大华，广电等）。<br />
              ☆公司使命：用持续创新的理念，连接人和服务。<br />
              ☆公司愿景：专注于行业软件开发领域，为客户提供整体解决方案。<br />
              ☆公司文化：以专注的精神追求卓越，用创新的思维拥抱变革。<br />

               &emsp;&emsp;在这互联网时代，骐骥预创网络宏图，建信息伟业，以激情和从容面对机遇，以智慧和真诚回报社会，我们坚信伟大的年代必定映射英雄的事业！
            </div>
          </div>
          <!-- <div class="text">
            <div style="text-indent: 2em" v-html="data.profile"></div>
          </div> -->
        </div>
        <div class="right animated" :class="{ bounceInRight: show1 }">
          <Carousel autoplay v-model="value" loop arrow="never" radius-dot>
            <CarouselItem>
              <img src="../assets/img/index/zcy01.jpeg" />
            </CarouselItem>
            <CarouselItem>
              <img src="../assets/img/index/zcy02.png" />
            </CarouselItem>
          </Carousel>
        </div>
      </div>
    </div>
    <!--    企业使命与愿景-->
    <div class="content2">
      <div class="top">
        <div class="title">{{ missiontitle.title }}</div>
        <div class="line"></div>
        <div class="title1">{{ missiontitle.alias }}</div>
      </div>
      <div class="bom">
        <div class="item">
          <div class="text textbg1 animated" :class="{ bounceInLeft: show2 }">
            <div class="text1">
              <div class="text1box"></div>
              <div>01</div>
            </div>
            <div class="title">{{ mission[0].title }}</div>
            <div class="info">{{ mission[0].content }}</div>
            <div class="line"></div>
          </div>
          <img
            class="img border1 animated"
            :class="{ bounceInRight: show2 }"
            :src="mission[0].picture"
          />
        </div>
        <div class="item">
          <img
            class="img border2 animated"
            :class="{ bounceInLeft: show2 }"
            :src="mission[1].picture"
          />
          <div class="text textbg2 animated" :class="{ bounceInRight: show2 }">
            <div class="text1">
              <div class="text1box"></div>
              <div>02</div>
            </div>
            <div class="title">{{ mission[1].title }}</div>
            <div class="info">{{ mission[1].content }}</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
    <!--    企业文化-->
    <div class="content3">
      <div class="top">
        <div class="title">{{ Culturetitle.title }}</div>
        <div class="line"></div>
        <div class="title1">{{ Culturetitle.alias }}</div>
      </div>
      <div class="bom">
        <div
          class="item"
          v-for="(item, index) in Culture"
          :key="index"
          @mouseleave="mouseLeave"
          @mouseover="mouseOver(item.index)"
        >
          <div
            class="outside animated"
            :class="{ fadeOutDown: index == curIndex }"
          >
            <img :src="item.picture" class="img" />
            <div class="imgb"></div>
            <div class="text">
              <div>
                <div class="text1">{{ item.title }}</div>
                <div class="text2">{{ item.alias }}</div>
              </div>
            </div>
          </div>
          <div class="inside animated" :class="{ fadeInUp: index == curIndex }">
            <div class="text3">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    共同价值观-->
    <div class="content4">
      <div class="top">
        <div class="title">{{ Commontitle.title }}</div>
        <div class="line"></div>
        <div class="title1">{{ Commontitle.alias }}</div>
      </div>
      <div class="bom">
        <div
          class="item"
          :class="{ active: index == curIndex1 }"
          v-for="(item, index) in Common"
          :key="index"
          @mouseleave="mouseLeave1"
          @mouseover="mouseOver1(item.index)"
        >
          {{ item.content }}
        </div>
      </div>
    </div>
    <!--    发展历程-->
    <div class="content5">
      <div class="top" style="">
        <div class="title">发展历程</div>
        <div class="line"></div>
        <div class="title1">THE DEVELOPMENT COURSE</div>
        <img
          style="height: 350px"
          src="../assets/img/index/fazhanglicheng.png"
        />
      </div>
      <div class="bom" v-for="(i, index) in datalist" :key="i.id">
        <div class="left">
          <div v-if="index % 2 !== 1" class="item ci1">{{ i.event }}</div>
        </div>

        <div class="cen">
          <!--          左边-->
          <div class="item ci1" v-if="index % 2 !== 1">
            {{ i.year }}
            <img src="../assets/img/index/img-history1.png" class="img-h1" />
          </div>
          <!--          右边-->
          <div class="item ci2" v-if="index % 2 != 0">
            {{ i.year }}
            <img src="../assets/img/index/img-history2.png" class="img-h2" />
          </div>
          <div class="line"></div>
        </div>
        <div class="right" v-if="index % 2 != 0">
          <div class="item ri1">
            {{ i.event }}
          </div>
        </div>
      </div>
    </div>
    <!--    公司荣誉-->
    <div class="content6">
      <div class="top">
        <div class="title">{{ Senioritytitle.title }}</div>
        <div class="line"></div>
        <div class="title1">{{ Senioritytitle.alias }}</div>
      </div>
      <div class="bom">
        <Carousel v-model="curIndex3" arrow="always" dots="none">
          <CarouselItem>
            <div class="carousel-item">
              <div class="box">
                <div
                  class="item"
                  v-for="(item, index) in honorList1"
                  :key="index"
                >
                  <img class="img" :src="item.picture" />
                  <div class="text">公司资质展示</div>
                </div>
              </div>
            </div>
          </CarouselItem>

          <CarouselItem>
            <div class="carousel-item">
              <div class="box">
                <div
                  class="item"
                  v-for="(item, index) in honorList2"
                  :key="index"
                >
                  <img class="img" :src="item.picture" />
                  <div class="text">{{ item.content }}</div>
                </div>
              </div>
            </div>
          </CarouselItem>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from "../utils/http.js";
import config from "../config/index";

export default {
  components: {},
  props: {},
  data() {
    return {
      value: 0,
      datalist: [],
      data: [],
      activity: [],
      mission: [{ title: "" }, { title: "" }],
      missiontitle: {},
      Culture: [],
      Culturetitle: {},
      Common: [],
      Commontitle: {},
      Seniority: [],
      Senioritytitle: {},

      curIndex: null,
      curIndex1: null,
      honorList1: [],
      honorList2: [],
      curIndex2: null,
      curIndex3: 0,
      num: 0,
      moveL: false,
      moveR: false,
      show1: false,
      show2: false,
      show3: false,
    };
  },
  watch: {},
  computed: {},

  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  created() {
    this.toTop();

    this.lode();
  },
  methods: {
    lode() {
      let data = {
        //请求参数
        username: "t1",
        password: "abc123..",
        code: "zjqj",
      };
      let url = "/login";
      post(url, data).then((res) => {
        localStorage.setItem("token", res.token);
        this.ceshi();
        this.Cultureurl();
        this.missionurl();
        this.Commonvalue();
        this.qualifications();
      });
    },
    ceshi() {
      let url = "/portal/company/6";
      get(url, null).then((res) => {
        this.data = res.data.company;
        this.datalist = res.data.developments;
      });
      let activityurl = "/portal/activity/list";
      get(activityurl, null).then((res) => {
        this.activity = res.rows;
        for (let i = 0; i < this.activity.length; i++) {
          this.activity[i].img = config.imageUrl + this.activity[i].img;
        }
      });
    },
    missionurl() {
      let missionurl =
        "/portal/article/listByCompanyIdAndClassifyId?companyId=6&classifyId=1";
      get(missionurl, null).then((res) => {
        this.missiontitle = res.companyClassify;
        this.mission = res.companyArticles;
        for (let i = 0; i < this.mission.length; i++) {
          this.mission[i].picture = config.imageUrl + this.mission[i].picture;
        }
      });
    },

    Cultureurl() {
      let Cultureurl =
        "/portal/article/listByCompanyIdAndClassifyId?companyId=6&classifyId=2";
      get(Cultureurl, null).then((res) => {
        this.Culturetitle = res.companyClassify;
        this.Culture = res.companyArticles;
        for (let i = 0; i < this.Culture.length; i++) {
          this.Culture[i].picture = config.imageUrl + this.Culture[i].picture;
          this.Culture[i].index = i;
        }
      });
    },
    Commonvalue() {
      let Commonurl =
        "/portal/article/listByCompanyIdAndClassifyId?companyId=6&classifyId=3";
      get(Commonurl, null).then((res) => {
        this.Commontitle = res.companyClassify;
        this.Common = res.companyArticles;
        for (let i = 0; i < this.Common.length; i++) {
          this.Common[i].picture = config.imageUrl + this.Common[i].picture;
          this.Common[i].index = i;
        }
      });
    },
    qualifications() {
      let Seniorityurl =
        "/portal/article/listByCompanyIdAndClassifyId?companyId=6&classifyId=4";
      get(Seniorityurl, null).then((res) => {
        console.log(res, "***9999*****");
        this.Senioritytitle = res.companyClassify;
        this.Seniority = res.companyArticles;
        for (let i = 0; i < this.Seniority.length; i++) {
          this.Seniority[i].picture =
            config.imageUrl + this.Seniority[i].picture;
        }
        this.honorList1 = this.Seniority.slice(0, 4);
        for (let i = 0; i < this.honorList1.length; i++) {
          this.honorList1[i].index = i;
        }
        this.honorList2 = this.Seniority.slice(4, this.Seniority.length);
        for (let i = 0; i < this.honorList2.length; i++) {
          this.honorList2[i].index = i;
        }
      });
    },

    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
    mouseOver(i) {
      this.curIndex = i;
    },
    mouseLeave() {
      this.curIndex = null;
    },
    mouseOver1(i) {
      this.curIndex1 = i;
    },
    mouseLeave1() {
      this.curIndex1 = null;
    },
    leftLeave() {
      this.moveL = false;
    },
    leftOver() {
      this.moveL = true;
      let time = setInterval(() => {
        if (this.moveL) {
          if (this.num > -740) {
            this.num -= 2;
          }
        } else {
          clearInterval(time);
        }
      }, 10);
    },
    rightLeave() {
      this.moveR = false;
      this.time = null;
    },
    rightOver() {
      this.moveR = true;
      let time = setInterval(() => {
        if (this.moveR) {
          if (this.num < 0) {
            this.num += 2;
          }
        } else {
          clearInterval(time);
        }
      }, 10);
    },
    handleScroll() {
      var scrollTop = document.documentElement.scrollTop;
      // console.log(scrollTop);
      if (scrollTop >= 200) {
        this.show1 = true;
      }
      if (scrollTop >= 850) {
        this.show2 = true;
      }
      if (scrollTop >= 1140) {
        this.show3 = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  text-align: center;
  margin-top: 30px;

  .title {
    color: #000028;
    font-size: 24px;
  }

  .line {
    width: 40px;
    height: 1px;
    background: #ef8200;
    opacity: 1;
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 7px;
  }

  .title1 {
    font-size: 14px;
    color: #999999;
  }
}

.content {
  width: 1900px;
  height: 860px;
  margin: 0 auto;
  padding-top: 70px;
  border-radius: 10px !important;

  .right {
    width: 1900px;
    height: 860px;

    /deep/ .ivu-carousel-list {
      width: 1900px;
      height: 860px;
      border-radius: 10px !important;
    }

    .swiper2 {
      width: 1900px;
      height: 860px;
      border-radius: 10px !important;
    }
  }
}

.content1 {
  width: 1600px;
  height: 689px;
  margin: 0 auto;
  padding-top: 70px;

  .bom {
    margin-top: 60px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;

    .left {
      width: 620px;
      height: 400px;

      .title {
        color: #4d4d4d;
        font-size: 20px;

        .line {
          width: 40px;
          height: 3px;
          background: #ef8200;
          opacity: 1;
          margin-top: 10px;
        }
        .words {
          font-size: 14px;
          line-height: 24px;
          color: #666666;
          overflow: hidden;
          letter-spacing: 1px;
        }
      }

      .text {
        margin-top: 35px;
        color: #000014;
        line-height: 30px;
        font-size: 16px;
        letter-spacing: 2px;
        text-align: justify;
      }
    }

    .right {
      width: 800px;
      height: 420px;

      /deep/ .ivu-carousel-list {
        width: 800px;
        height: 420px;
        border-radius: 10px !important;
      }

      .swiper {
        width: 800px;
        height: 420px;
        border-radius: 10px !important;
      }
    }
  }
}

.content2 {
  width: 100%;
  height: 770px;
  background: #f0f5fa;
  padding-top: 30px;

  .bom {
    width: 1600px;
    height: 580px;
    margin: 0 auto;
    margin-top: 60px;

    .item {
      width: 1600px;
      height: 280px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;

      .img {
        width: 1190px;
        height: 280px;
      }

      .border1 {
        border-top-right-radius: 10px;
      }

      .border2 {
        border-bottom-left-radius: 10px;
      }

      .text {
        width: 400px;
        height: 280px;
        color: #fff;

        .text1 {
          margin-top: 60px;
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          margin-left: 50px;
          margin-right: 42px;

          .text1box {
            width: 22px;
            height: 4px;
            background: #fff;
          }
        }

        .title {
          font-size: 20px;
          margin-left: 50px;
          margin-right: 42px;
          margin-top: 60px;
        }

        .info {
          margin-top: 10px;
          font-size: 16px;
          margin-left: 50px;
          margin-right: 42px;
        }

        .line {
          margin-top: 24px;
          width: 400px;
          height: 1px;
          background: #fff;
        }
      }

      .textbg1 {
        background: #10b4fc;
        border-top-left-radius: 10px;
      }

      .textbg2 {
        background: #ef8200;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

.content3 {
  padding-top: 70px;
  width: 100%;
  height: 760px;

  .bom {
    width: 1600px;
    height: 500px;
    margin: 0 auto;
    display: flex;
    margin-top: 60px;
    justify-content: space-between;

    .item {
      overflow: hidden;
      width: 350px;
      height: 500px;
      border-radius: 10px;

      .outside {
        z-index: 10;
        position: relative;
        border-radius: 10px;

        .img {
          width: 350px;
          height: 500px;
          position: absolute;
          z-index: 10;
        }

        .imgb {
          width: 350px;
          height: 500px;
          background: rgba(0, 64, 159, 0.5);
          position: absolute;
          z-index: 11;
        }

        .text {
          width: 350px;
          height: 500px;
          position: absolute;
          z-index: 20;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;

          .text1 {
            font-size: 20px;
            font-weight: 500;
            text-align: center;
          }

          .text2 {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .inside {
        position: relative;
        z-index: 9;
        width: 350px;
        height: 500px;
        background: #ef8200;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        .text3 {
          text-align: justify;
          height: 220px;
          width: 262px;
          line-height: 24px;
          color: #ffffff;
          font-size: 16px;
          border-top: 2px solid #fff;
          border-bottom: 2px solid #fff;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.content4 {
  width: 100%;
  height: 402px;
  background-image: url("../assets/img/index/content-bg.png");
  background-repeat: no-repeat;
  background-size: auto;
  padding-top: 30px;

  .color {
    color: #fff;
  }

  .bom {
    width: 1600px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 60px auto 0;

    .item {
      width: 512px;
      height: 68px;
      text-align: center;
      line-height: 68px;
      background: #ffffff;
      opacity: 1;
      border-radius: 10px;
      margin-bottom: 33px;
      padding-left: 40px;
      font-size: 16px;
      color: #000028;
    }

    .active {
      position: relative;
      animation: mymove 0.5s;
      animation-fill-mode: forwards;
    }

    @keyframes mymove {
      from {
        top: 0px;
      }

      to {
        top: -10px;
      }
    }

    .item:hover {
      box-shadow: 0px 0px 6px rgba(16, 180, 252, 0.79);
    }
  }
}

.content5 {
  width: 100%;
  min-height: 300px;
  padding-top: 70px;
  .exper {
    text-align: left;

    height: 200px;
    width: 600px;
    margin: 0 auto;
  }
  .bom {
    width: 1036px;
    display: flex;
    margin: 50px auto 0;

    .left {
      width: 394px;
      height: 100px;

      .item {
        width: 394px;
        padding: 22px 16px;
        color: #000014;
        font-size: 16px;
        background: #f8f8f8;
        opacity: 1;
        border-radius: 10px;
      }

      .item:hover {
        background: #ef8200;
        color: #ffffff;
      }

      .li1 {
        margin-top: 10px;
      }

      .li2 {
        margin-top: 10px;
      }
    }

    .cen {
      width: 246px;
      height: 100px;
      position: relative;

      .item {
        width: 102px;
        height: 38px;
        background: #ef8200;
        opacity: 1;
        border-radius: 19px;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        line-height: 38px;
        margin: 0 auto;
        position: relative;

        .img-h1 {
          width: 80px;
          height: 16px;
          position: absolute;
          top: 12px;
          left: -60px;
        }

        .img-h2 {
          width: 80px;
          height: 16px;
          position: absolute;
          top: 12px;
          right: -60px;
        }
      }

      .ci1 {
        margin-top: 10px;
      }

      .ci2 {
        margin-top: 10px;
      }

      //11111
      .line {
        position: absolute;
        top: 10px;
        left: 123px;
        z-index: -1;
        width: 6px;
        height: 650px;
        background: #ebebeb;
        border-radius: 3px;
      }
    }

    .right {
      width: 388px;
      height: 100px;

      .item {
        width: 388px;
        padding: 22px 16px;
        color: #000014;
        font-size: 16px;
        background: #f8f8f8;
        opacity: 1;
        border-radius: 10px;
        line-height: 20px;
      }

      .item:hover {
        background: #ef8200;
        color: #ffffff;
      }
    }
  }
}

.content6 {
  width: 100%;
  height: 740px;
  background: #f0f5fa;
  padding-top: 30px;

  .bom {
    margin: 0 auto;
    width: 1600px;
    height: 520px;
    margin-top: 60px;

    .carousel-item {
      width: 1600px;
      height: 520px;

      .box {
        display: flex;
        justify-content: space-around;
        width: 1400px;
        height: 520px;
        margin: 0 auto;

        .item {
          width: 300px;
          border-radius: 10px;
          background: #fff;

          img {
            width: 300px;
            height: 400px;
          }

          .text {
            width: 300px;
            height: 120px;
            color: #000014;
            font-size: 16px;
            display: flex;
            align-items: center;
            padding-left: 26px;
            padding-right: 34px;
          }
        }
      }
    }
  }
}
</style>
