<template>
  <div class="page">
    <introductionBom></introductionBom>
  </div>
</template>

<script>
// import floatBar from "../../components/FloatBar";
import introductionBom from "../../components/introductionBom";
export default {
  metaInfo: {
    title: "浙江骐骥软软件科技",
  },
  components: {
    introductionBom,
  },

};
</script>
<style lang="less" scoped>
.page {
  width: 100%;
}

.banner {
  width: 100%;
  height: 100vh;
  .video {
    position: relative;
    z-index: 100;
    margin-top: 66px;
    width: 100%;
    height: calc(100% - 66px);
  }
}
</style>

